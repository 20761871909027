import React, { useEffect } from 'react';
import { FormControl, FormControlLabel, Radio, RadioGroup } from '@material-ui/core';


// project imports
import { SET_TIMEZONE } from 'store/actions'; // THEME_RTL
import SubCard from 'ui-component/cards/SubCard';
import { useDispatch, useSelector } from 'react-redux';

const Timezone = () => {
    const dispatch = useDispatch();
    const timezone = useSelector((state) => state.option.timezone);
    const [timeZoneConfig, setTimeZoneConfig] = React.useState(timezone);
    useEffect(() => dispatch({ type: SET_TIMEZONE, timezone: timeZoneConfig }), [dispatch, timeZoneConfig]);

    return (
        <SubCard title="TimeZone">
            <FormControl component="fieldset">
                <RadioGroup
                    row
                    aria-label="layout"
                    value={timeZoneConfig}
                    onChange={(e) => setTimeZoneConfig(e.target.value)}
                    name="timezone_config"
                >
                    <FormControlLabel
                        value="KST"
                        control={<Radio />}
                        label="KST"
                        sx={{
                            '& .MuiSvgIcon-root': { fontSize: 28 },
                            '& .MuiFormControlLabel-label': { color: 'grey.900' }
                        }}
                    />
                    <FormControlLabel
                        value="UTC"
                        control={<Radio />}
                        label="UTC"
                        sx={{
                            '& .MuiSvgIcon-root': { fontSize: 28 },
                            '& .MuiFormControlLabel-label': { color: 'grey.900' }
                        }}
                    />
                </RadioGroup>
            </FormControl>
        </SubCard>
    );
};
export default Timezone;
