// action - account reducer
export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const ACCOUNT_INITIALIZE = 'ACCOUNT_INITIALIZE';
export const FIREBASE_STATE_CHANGED = 'FIREBASE_STATE_CHANGED';

// action - customization reducer
export const SET_MENU = '@customization/SET_MENU';
export const MENU_TOGGLE = '@customization/MENU_TOGGLE';
export const MENU_OPEN = '@customization/MENU_OPEN';
export const MENU_TYPE = '@customization/MENU_TYPE';
export const PRESET_COLORS = '@customization/PRESET_COLORS';
export const THEME_LOCALE = '@customization/THEME_LOCALE';
export const THEME_RTL = '@customization/THEME_RTL';
export const SET_FONT_FAMILY = '@customization/SET_FONT_FAMILY';
export const SET_BORDER_RADIUS = '@customization/SET_BORDER_RADIUS';
export const SET_OUTLINED_FILLED = '@customization/SET_OUTLINED_FILLED';

// action - snackbar
export const SNACKBAR_OPEN = '@snackbar/SNACKBAR_OPEN';

// action - cart
export const ADD_PRODUCTS = '@cart/ADD_PRODUCTS';
export const REMOVE_PRODUCT = '@cart/REMOVE_PRODUCT';
export const UPDATE_QUANTITY = '@cart/UPDATE_QUANTITY';
export const NEXT_STEP = '@cart/NEXT_STEP';
export const BACK_STEP = '@cart/BACK_STEP';
export const SET_STEP = '@cart/SET_STEP';
export const SET_BILLING_ADDRESS = '@cart/SET_BILLING_ADDRESS';
export const SET_DISCOUNT = '@cart/SET_DISCOUNT';
export const SET_SHIPPING_CHARGE = '@cart/SET_SHIPPING_CHARGE';
export const SET_PAYMENT_METHOD = '@cart/SET_PAYMENT_METHOD';
export const SET_PAYMENT_CARD = '@cart/SET_PAYMENT_CARD';
export const RESET_CART = '@cart/RESET_CART';

//#region Custom 리덕스
// ########### date ###########
// action - date
export const SEARCH_DATE = 'date/SEARCH_DATE';
// action 함수 생성
export const searchDate = (startDate, endDate) => ({ type: SEARCH_DATE, startDate, endDate });

// ########### index & cluster ###########
export const SET_INDEX = 'customerindex/SET_INDEX';
export const SET_CLUSTER = 'customerindex/SET_CLUSTER';
export const setIndex = (index) => ({ type: SET_INDEX, index });
export const setCluster = (cluster) => ({ type: SET_CLUSTER, cluster });

// ########### User ###########
// export const SET_NICKNAME = 'user/SET_NICKNAME';
export const SET_NAME = 'user/SET_NAME';
export const SET_ROLE = 'user/SET_ROLE';
export const SET_DISPLAYROLE = 'user/SET_DISPLAYROLE';
export const setName = ({ name, id }) => ({ type: SET_NAME, name, id });
// export const setNickname = (nickname) => ({ type: SET_NICKNAME, nickname });
export const setRole = (role) => ({ type: SET_ROLE, role });
export const setDisplayRole = (displayRole) => ({ type: SET_DISPLAYROLE, displayRole });

// ########### Refresh & TimeZone ###########
export const REFRESH = 'option/REFRESH';
export const SET_TIMEZONE = 'option/SET_TIMEZONE';
export const refresh = () => ({ type: REFRESH });
export const setTimezone = (timezone) => ({ type: REFRESH, timezone });

// ########### Modal ###########
export const SET_TITLE = 'modal/TITLE';
export const SET_CONTENTS = 'modal/CONSTENTS';
export const SET_OPEN = 'modal/OPEN';
export const SET_MODAL = 'modal/MODAL';
export const setModal = (title, contents, open) => ({ type: SET_MODAL, title, contents, open });
export const setTitle = (title) => ({ type: SET_TITLE, title });
export const setContents = (contents) => ({ type: SET_CONTENTS, contents });
export const setOpen = (open) => ({ type: SET_OPEN, open });
//#endregion
