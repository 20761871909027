// assets
import {
    IconDashboard,
    IconFileInfo,
    IconCertificate,
    IconWorld,
    IconGitFork,
    IconRecycle,
    IconChartBar,
    IconChartArea,
    IconStars,
    IconClick,
    IconChartPie,
    IconEyeCheck,
    IconUsers
} from '@tabler/icons';
import { FormattedMessage } from 'react-intl';

// constant
const icons = {
    IconDashboard,
    IconFileInfo,
    IconCertificate,
    IconWorld,
    IconGitFork,
    IconRecycle,
    IconChartBar,
    IconChartArea,
    IconStars,
    IconClick,
    IconChartPie,
    IconEyeCheck,
    IconUsers
};

// ===========================|| UTILITIES MENU ITEMS ||=========================== //

const logsystem = {
    id: 'CMmenu',
    title: '',
    type: 'group',
    children: [
        {
            id: 'dashboard',
            title: <FormattedMessage id="dashboard" />,
            type: 'item',
            url: '/dashboard/default',
            icon: icons.IconDashboard,
            breadcrumbs: false
        },
        {
            id: 'edge-trend',
            title: <FormattedMessage id="edge-trend" />,
            type: 'item',
            url: '/logsystem/EdgeTrend',
            icon: icons.IconChartArea
        },
        {
            id: 'popular-object',
            title: <FormattedMessage id="popular-object" />,
            type: 'item',
            url: '/logsystem/PopularObject',
            icon: icons.IconStars
        },
        {
            id: 'top-referrer',
            title: <FormattedMessage id="top-referrer" />,
            type: 'item',
            url: '/logsystem/TopReferrer',
            icon: icons.IconClick
        },
        {
            id: 'usage',
            title: <FormattedMessage id="usage" />,
            type: 'item',
            url: '/logsystem/Usage',
            icon: icons.IconChartPie
        },
        {
            id: 'viewer',
            title: <FormattedMessage id="viewer" />,
            type: 'item',
            url: '/logsystem/Viewer',
            icon: icons.IconEyeCheck
        },
        {
            id: 'unique-user-trend',
            title: <FormattedMessage id="unique-user-trend" />,
            type: 'item',
            url: '/logsystem/UniqueUserTrend',
            icon: icons.IconUsers
        }
    ]
};

export default logsystem;
