// action - state management
import { REFRESH, SET_TIMEZONE } from './actions';
// ===========================|| DATE REDUCER ||=========================== //

const initialState = {
    refresh: false,
    timezone: 'KST'
};

const optionReducer = (state = initialState, action) => {
    switch (action.type) {
        case REFRESH: {
            return {
                ...state,
                refresh: !state.refresh
            };
        }
        case SET_TIMEZONE: {
            return {
                ...state,
                timezone: action.timezone
            };
        }
        default: {
            return { ...state };
        }
    }
};

export default optionReducer;
