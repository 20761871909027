// import dashboard from './dashboard';
import cmMenu from './cmMenu';
import las from './las';
// import widget from './widget';
// import application from './application';
// import forms from './forms';
// import elements from './elements';
// import pages from './pages';
// import utilities from './utilities';
// import support from './support';
// import other from './other';

// ===========================|| MENU ITEMS ||=========================== //

// const menuItems = {
//     items: [cmMenu, las, widget, application, forms, elements, pages, utilities, support, other]
// };

const menuItems = {
    items: [cmMenu,las]
};

export default menuItems;
