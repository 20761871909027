import React from 'react';

// material-ui
// import { useTheme } from '@material-ui/styles';
import { Fab, IconButton, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

// assets
import { IconRefresh } from '@tabler/icons';

// project imports
import AnimateButton from 'ui-component/extended/AnimateButton';
import { useDispatch } from 'react-redux';
import { REFRESH } from 'store/actions';

// ===========================|| LIVE CUSTOMIZATION ||=========================== //

const useStyles = makeStyles((theme) => ({
    toolbar: {
        bottom: 450,
        position: 'fixed',
        right: 0,
        zIndex: theme.zIndex.speedDial,
        boxShadow: theme.customShadows.secondary,

        [theme.breakpoints.down('md')]: {
            bottom: 500,
        }
    }
}));

const Refresh = () => {
    const classes = useStyles();
    // const theme = useTheme();
    const dispatch = useDispatch();
    const onClick = () => {
        dispatch({ type: REFRESH });
    };
    return (
        <>
            {/* toggle button */}
            <Tooltip title="Refresh">
                <Fab
                    className={classes.toolbar}
                    component="div"
                    onClick={() => onClick()}
                    size="medium"
                    variant="string"
                    color="primary"
                
                >
                    <AnimateButton type="rotate">
                        <IconButton color="inherit" size="large" disableRipple>
                            <IconRefresh />
                        </IconButton>
                    </AnimateButton>
                </Fab>
            </Tooltip>
        </>
    );
};

export default Refresh;
