import { createStore } from 'redux';
import { persistStore } from 'redux-persist';
import { composeWithDevTools } from 'redux-devtools-extension'; // 리덕스 개발자 도구
import reducer from './reducer';

// ===========================|| REDUX - MAIN STORE ||=========================== //

const store = createStore(reducer, composeWithDevTools());
const persister = persistStore(store);

export { store, persister };
