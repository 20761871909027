import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setCookie } from '../../../src/utils/cookie';
import '../../static/fonts/font.css';
// material-ui
import { makeStyles, useTheme } from '@material-ui/styles';
import {
    Avatar,
    ButtonBase,
    Drawer,
    Fab,
    FormControl,
    FormControlLabel,
    FormLabel,
    Grid,
    IconButton,
    Radio,
    RadioGroup,
    Tooltip
} from '@material-ui/core';

// third-party
import PerfectScrollbar from 'react-perfect-scrollbar';

// project imports
import SubCard from 'ui-component/cards/SubCard';
import AnimateButton from 'ui-component/extended/AnimateButton';
import { MENU_TYPE, PRESET_COLORS, SET_FONT_FAMILY } from 'store/actions'; // THEME_RTL
import { gridSpacing } from 'store/constant';

// color import
import colors from 'assets/scss/_themes-vars.module.scss';
import theme1 from 'assets/scss/_theme1.module.scss';
import theme2 from 'assets/scss/_theme2.module.scss';
import theme3 from 'assets/scss/_theme3.module.scss';
import theme4 from 'assets/scss/_theme4.module.scss';
import theme5 from 'assets/scss/_theme5.module.scss';
import theme6 from 'assets/scss/_theme6.module.scss';

// assets
import { IconChecks, IconSettings } from '@tabler/icons';

const PresetColor = ({ color, presetColor, setPresetColor }) => (
    <Grid item>
        <ButtonBase
            sx={{ borderRadius: '12px' }}
            onClick={() => {
                setPresetColor(color.id);
                setCookie('presetColor', color.id);
            }}
        >
            <Avatar
                variant="rounded"
                color="inherit"
                sx={{
                    background: `linear-gradient(135deg, ${color.primary} 50%,  ${color.secondary}  50%)`,
                    opacity: presetColor === color.id ? 0.6 : 1
                }}
            >
                {presetColor === color.id && <IconChecks color="#fff" />}
            </Avatar>
        </ButtonBase>
    </Grid>
);

PresetColor.propTypes = {
    color: PropTypes.shape({
        id: PropTypes.string,
        primary: PropTypes.string,
        secondary: PropTypes.string
    }),
    presetColor: PropTypes.string,
    setPresetColor: PropTypes.func
};

// ===========================|| LIVE CUSTOMIZATION ||=========================== //

const useStyles = makeStyles((theme) => ({
    toolbar: {
        bottom: 395,
        position: 'fixed',
        right: 0,
        zIndex: theme.zIndex.speedDial,
        boxShadow: theme.customShadows.secondary,

        [theme.breakpoints.down('md')]: {
            bottom: 450
        }
    }
}));

const Customization = () => {
    const classes = useStyles();
    const theme = useTheme();
    const dispatch = useDispatch();
    const customization = useSelector((state) => state.customization);

    // drawer on/off
    const [open, setOpen] = React.useState(false);
    const handleToggle = () => {
        setOpen(!open);
    };

    // state - layout type
    const [navTypeConfig, setNavTypeConfig] = React.useState(customization.navType);
    useEffect(() => dispatch({ type: MENU_TYPE, navType: navTypeConfig }), [dispatch, navTypeConfig]);

    // state - preset color
    const [presetColorConfig, setPresetColorConfig] = React.useState(customization.presetColor);
    useEffect(() => dispatch({ type: PRESET_COLORS, presetColor: presetColorConfig }), [dispatch, presetColorConfig]);

    // state - border radius
    // const [borderRadiusConfig, setBorderRadiusConfig] = React.useState(customization.borderRadius);

    // useEffect(() => dispatch({ type: SET_BORDER_RADIUS, borderRadius: borderRadiusConfig }), [dispatch, borderRadiusConfig]);

    if (customization.rtlLayout) {
        document.querySelector('body').setAttribute('dir', 'rtl');
    }
    let initialFont;
    switch (customization.fontFamily) {
        case `'Inter', sans-serif`:
            initialFont = 'Inter';
            break;
        case `'Poppins', sans-serif`:
            initialFont = 'Poppins';
            break;
        case `'TodayM', sans-serif`:
            initialFont = 'TodayM';
            break;
        case `'SeoulNamsanM', sans-serif`:
            initialFont = 'SeoulNamsanM';
            break;
        case `'SDSamliphopangcheTTFBasic', sans-serif`:
            initialFont = 'SDSamliphopangcheTTFBasic';
            break;
        case `'NanumGothic', sans-serif`:
            initialFont = 'NanumGothic';
            break;
        case `'lssM', sans-serif`:
            initialFont = 'lssM';
            break;
        case `'Gosanja', sans-serif`:
            initialFont = 'Gosanja';
            break;
        case `'SCDream5', sans-serif`:
            initialFont = 'SCDream5';
            break;
        case `'Roboto', sans-serif`:
        default:
            initialFont = 'Roboto';
            break;
    }
    // state - font family
    const [fontFamily, setFontFamily] = React.useState(initialFont);
    useEffect(() => {
        let newFont;
        switch (fontFamily) {
            case 'Inter':
                newFont = `'Inter', sans-serif`;
                break;
            case 'Poppins':
                newFont = `'Poppins', sans-serif`;
                break;
            case 'TodayM':
                newFont = `'TodayM', sans-serif`;
                break;
            case 'SeoulNamsanM':
                newFont = `'SeoulNamsanM', sans-serif`;
                break;
            case 'SDSamliphopangcheTTFBasic':
                newFont = `'SDSamliphopangcheTTFBasic', sans-serif`;
                break;
            case 'NanumGothic':
                newFont = `'NanumGothic', sans-serif`;
                break;
            case 'lssM':
                newFont = `'lssM', sans-serif`;
                break;
            case 'Gosanja':
                newFont = `'Gosanja', sans-serif`;
                break;
            case 'SCDream5':
                newFont = `'SCDream5', sans-serif`;
                break;
            case 'Roboto':
            default:
                newFont = `'Roboto', sans-serif`;
                break;
        }
        dispatch({ type: SET_FONT_FAMILY, fontFamily: newFont });
    }, [dispatch, fontFamily]);
    const colorOptions = [
        {
            id: 'default',
            primary: theme.palette.mode === 'dark' ? colors.darkPrimaryMain : colors.primaryMain,
            secondary: theme.palette.mode === 'dark' ? colors.darkSecondaryMain : colors.secondaryMain
        },
        {
            id: 'theme1',
            primary: theme.palette.mode === 'dark' ? theme1.darkPrimaryMain : theme1.primaryMain,
            secondary: theme.palette.mode === 'dark' ? theme1.darkSecondaryMain : theme1.secondaryMain
        },
        {
            id: 'theme2',
            primary: theme.palette.mode === 'dark' ? theme2.darkPrimaryMain : theme2.primaryMain,
            secondary: theme.palette.mode === 'dark' ? theme2.darkSecondaryMain : theme2.secondaryMain
        },
        {
            id: 'theme3',
            primary: theme.palette.mode === 'dark' ? theme3.darkPrimaryMain : theme3.primaryMain,
            secondary: theme.palette.mode === 'dark' ? theme3.darkSecondaryMain : theme3.secondaryMain
        },
        {
            id: 'theme4',
            primary: theme.palette.mode === 'dark' ? theme4.darkPrimaryMain : theme4.primaryMain,
            secondary: theme.palette.mode === 'dark' ? theme4.darkSecondaryMain : theme4.secondaryMain
        },
        {
            id: 'theme5',
            primary: theme.palette.mode === 'dark' ? theme5.darkPrimaryMain : theme5.primaryMain,
            secondary: theme.palette.mode === 'dark' ? theme5.darkSecondaryMain : theme5.secondaryMain
        },
        {
            id: 'theme6',
            primary: theme.palette.mode === 'dark' ? theme6.darkPrimaryMain : theme6.primaryMain,
            secondary: theme.palette.mode === 'dark' ? theme6.darkSecondaryMain : theme6.secondaryMain
        }
    ];

    return (
        <>
            {/* toggle button */}

            <Tooltip title="Live Customize">
                <Fab className={classes.toolbar} component="div" onClick={handleToggle} size="medium" variant="string" color="secondary">
                    <AnimateButton type="rotate">
                        <IconButton color="inherit" size="large" disableRipple>
                            <IconSettings />
                        </IconButton>
                    </AnimateButton>
                </Fab>
            </Tooltip>

            <Drawer
                anchor="right"
                onClose={handleToggle}
                open={open}
                PaperProps={{
                    sx: {
                        width: 280
                    }
                }}
            >
                <PerfectScrollbar component="div">
                    <Grid container spacing={gridSpacing} sx={{ p: 3 }}>
                        <Grid item xs={12}>
                            {/* layout type */}
                            <SubCard title="Layout">
                                <FormControl component="fieldset">
                                    <FormLabel component="legend">Mode</FormLabel>
                                    <RadioGroup
                                        row
                                        aria-label="layout"
                                        value={navTypeConfig}
                                        onChange={(e) => {
                                            setCookie('theme', e.target.value);
                                            setNavTypeConfig(e.target.value);
                                        }}
                                        name="row-radio-buttons-group"
                                    >
                                        <FormControlLabel
                                            value="light"
                                            control={<Radio />}
                                            label="light"
                                            sx={{
                                                '& .MuiSvgIcon-root': { fontSize: 28 },
                                                '& .MuiFormControlLabel-label': { color: 'grey.900' }
                                            }}
                                        />
                                        <FormControlLabel
                                            value="dark"
                                            control={<Radio />}
                                            label="dark"
                                            sx={{
                                                '& .MuiSvgIcon-root': { fontSize: 28 },
                                                '& .MuiFormControlLabel-label': { color: 'grey.900' }
                                            }}
                                        />
                                    </RadioGroup>
                                </FormControl>
                            </SubCard>
                        </Grid>
                        <Grid item xs={12}>
                            {/* Theme Preset Color */}
                            <SubCard title="Preset Color">
                                <Grid item container spacing={2} alignItems="center">
                                    {colorOptions.map((color, index) => (
                                        <PresetColor
                                            key={index}
                                            color={color}
                                            presetColor={presetColorConfig}
                                            setPresetColor={setPresetColorConfig}
                                        />
                                    ))}
                                </Grid>
                            </SubCard>
                        </Grid>
                        <Grid item xs={12}>
                            {/* font family */}
                            <SubCard title="Font Family">
                                <FormControl>
                                    <RadioGroup
                                        aria-label="font-family"
                                        value={fontFamily}
                                        onChange={(e) => {
                                            setCookie('font', `'${e.target.value}', sans-serif`);
                                            setFontFamily(e.target.value);
                                        }}
                                        name="row-radio-buttons-group"
                                    >
                                        <FormControlLabel
                                            value="TodayM"
                                            control={<Radio />}
                                            label="잘풀리는오늘체"
                                            sx={{
                                                '& .MuiSvgIcon-root': { fontSize: 28 },
                                                '& .MuiFormControlLabel-label': { color: 'grey.900' }
                                            }}
                                        />
                                        <FormControlLabel
                                            value="SeoulNamsanM"
                                            control={<Radio />}
                                            label="서울남산체"
                                            sx={{
                                                '& .MuiSvgIcon-root': { fontSize: 28 },
                                                '& .MuiFormControlLabel-label': { color: 'grey.900' }
                                            }}
                                        />
                                        <FormControlLabel
                                            value="SDSamliphopangcheTTFBasic"
                                            control={<Radio />}
                                            label="산돌 삼립호빵체"
                                            sx={{
                                                '& .MuiSvgIcon-root': { fontSize: 28 },
                                                '& .MuiFormControlLabel-label': { color: 'grey.900' }
                                            }}
                                        />
                                        <FormControlLabel
                                            value="SCDream5"
                                            control={<Radio />}
                                            label="에스코어드림체"
                                            sx={{
                                                '& .MuiSvgIcon-root': { fontSize: 28 },
                                                '& .MuiFormControlLabel-label': { color: 'grey.900' }
                                            }}
                                        />
                                        <FormControlLabel
                                            value="NanumGothic"
                                            control={<Radio />}
                                            label="나눔고딕체"
                                            sx={{
                                                '& .MuiSvgIcon-root': { fontSize: 28 },
                                                '& .MuiFormControlLabel-label': { color: 'grey.900' }
                                            }}
                                        />
                                        <FormControlLabel
                                            value="lssM"
                                            control={<Radio />}
                                            label="이순신명조체"
                                            sx={{
                                                '& .MuiSvgIcon-root': { fontSize: 28 },
                                                '& .MuiFormControlLabel-label': { color: 'grey.900' }
                                            }}
                                        />
                                        <FormControlLabel
                                            value="Gosanja"
                                            control={<Radio />}
                                            label="고산자체"
                                            sx={{
                                                '& .MuiSvgIcon-root': { fontSize: 28 },
                                                '& .MuiFormControlLabel-label': { color: 'grey.900' }
                                            }}
                                        />
                                        <FormControlLabel
                                            value="Roboto"
                                            control={<Radio />}
                                            label="Roboto"
                                            sx={{
                                                '& .MuiSvgIcon-root': { fontSize: 28 },
                                                '& .MuiFormControlLabel-label': { color: 'grey.900' }
                                            }}
                                        />
                                        <FormControlLabel
                                            value="Poppins"
                                            control={<Radio />}
                                            label="Poppins"
                                            sx={{
                                                '& .MuiSvgIcon-root': { fontSize: 28 },
                                                '& .MuiFormControlLabel-label': { color: 'grey.900' }
                                            }}
                                        />
                                    </RadioGroup>
                                </FormControl>
                            </SubCard>
                        </Grid>
                    </Grid>
                </PerfectScrollbar>
            </Drawer>
        </>
    );
};

export default Customization;
