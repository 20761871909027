import React, { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';

// dashboard routing
const DashboardDefault = Loadable(lazy(() => import('views/dashboard/Default')));

// logSystem page routing
const EdgeTrend = Loadable(lazy(() => import('views/logsystem/EdgeTrend/index')));
const PopularObject = Loadable(lazy(() => import('views/logsystem/PopularObject/index')));
const TopReferrer = Loadable(lazy(() => import('views/logsystem/TopReferrer/index')));
const Usage = Loadable(lazy(() => import('views/logsystem/Usage/index')));
const Viewer = Loadable(lazy(() => import('views/logsystem/Viewer/index')));
const UniqueUserTrend = Loadable(lazy(() => import('views/logsystem/UniqueUserTrend/index')));

// lasManage page routing
const LasManage = Loadable(lazy(() => import('views/las/Manage/index')));

// error page routing
const MaintenanceError = Loadable(lazy(() => import('views/pages/maintenance/Error')));

// const Logout = lazy(() => import('./LogoutRoutes'));

// ===========================|| MAIN ROUTING ||=========================== //

const MainRoutes = {
    path: '/',
    element: <MainLayout />,
    children: [
        {
            path: '/',
            element: <DashboardDefault />
        },
        {
            path: '/dashboard/default',
            element: <DashboardDefault />
        },
        {
            path: '/logsystem/EdgeTrend',
            element: <EdgeTrend />
        },
        {
            path: '/logsystem/PopularObject',
            element: <PopularObject />
        },
        {
            path: '/logsystem/TopReferrer',
            element: <TopReferrer />
        },
        {
            path: '/logsystem/Usage',
            element: <Usage />
        },
        {
            path: '/logsystem/Viewer',
            element: <Viewer />
        },
        {
            path: '/logsystem/Viewer/:id',
            element: <Viewer />
        },
        {
            path: '/logsystem/UniqueUserTrend',
            element: <UniqueUserTrend />
        },
        {
            path: '/las/Manage',
            element: <LasManage />
        },
        {
            path: '/*',
            element: <MaintenanceError />
        }
    ]
};

export default MainRoutes;
