// assets
import { IconFileInfo, IconCertificate, IconWorld, IconGitFork, IconRecycle, IconChartBar } from '@tabler/icons';
import { FormattedMessage } from 'react-intl';

// constant
const icons = {
    IconFileInfo,
    IconCertificate,
    IconWorld,
    IconGitFork,
    IconRecycle,
    IconChartBar
};

// ===========================|| UTILITIES MENU ITEMS ||=========================== //

const logsystem = {
    id: 'dev',
    title: '개발자 메뉴',
    type: 'group',
    children: [
        {
            id: 'las-manage',
            title: <FormattedMessage id="las-manage" />,
            type: 'item',
            url: '/las/Manage',
            icon: icons.IconFileInfo
        }
    ]
};

export default logsystem;
