import React, { useEffect, useState } from 'react';

// material-ui
import { withStyles } from '@material-ui/styles';
import { Button, Dialog, IconButton, Stack, Typography } from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';

// assets
import CloseIcon from '@material-ui/icons/Close';
import { useDispatch, useSelector } from 'react-redux';
import { SET_OPEN } from 'store/actions';
// style constant
const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2.5)
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(2),
        top: theme.spacing(2),
        color: theme.palette.grey[500]
    }
});

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(3)
    }
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2)
    }
}))(MuiDialogActions);

// =============================|| DIALOG TITLE ||============================= //

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle className={classes.root} {...other}>
            {children}
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

// ===============================|| UI DIALOG - CUSTOMIZED ||=============================== //

export default function CustomizedDialogs() {
    const { title, contents, open, onCloseBtn } = useSelector(({ modal }) => modal);

    const dispatch = useDispatch();
    const [modalOpen, setModalOpen] = useState(open);
    const handleClose = () => {
        onCloseBtn ? onCloseBtn() : console.log('onClose not found');
        setModalOpen(false);
        dispatch({ type: SET_OPEN, open: false });
    };

    //모달
    useEffect(() => {
        setModalOpen(open);
    }, [open, dispatch]);

    return (
        <div>
            <Dialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={modalOpen}
                sx={{ '& .MuiDialog-paper': { pr: 0 } }}
            >
                <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                    {title}
                </DialogTitle>
                <DialogContent dividers>
                    <Stack spacing={2}>
                        <Typography variant="body2">{contents}</Typography>
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus variant="contained" size="small" onClick={handleClose}>
                        확인
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
