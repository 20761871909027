// action - state management
import { SET_DISPLAYROLE, SET_NAME, SET_ROLE } from './actions';
// ===========================|| DATE REDUCER ||=========================== //

const initialState = null;

const userReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_ROLE: {
            return {
                ...state,
                role: action.role
            };
        }
        case SET_NAME: {
            return {
                ...state,
                nickname: action.name,
                id: action.id
            };
        }
        case SET_DISPLAYROLE: {
            return {
                ...state,
                displayRole: action.displayRole
            };
        }
        default: {
            return { ...state };
        }
    }
};

export default userReducer;
