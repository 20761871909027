import React from 'react';
import ReactDOM from 'react-dom';

// third party
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';

// project imports
import { store } from './store';
import * as serviceWorker from './serviceWorker';
import App from './App';

import { ReactKeycloakProvider } from '@react-keycloak/web';
import keycloakconfig from './keycloak';
import Loader from 'react-loader-spinner';

// style + assets
import './assets/scss/style.scss';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';

const eventLogger = (event, error) => {
    // console.log('onKeycloakEvent', event, error);
    if(event === 'onTokenExpired') {
        keycloakconfig.logout();
    }
};

const tokenLogger = (tokens) => {
    // console.log('onKeycloakTokens', new Date(), tokens);
};

const initOptions = {
    onLoad: 'login-required',
    checkLoginIframe: false,
};

// ===========================|| REACT DOM RENDER  ||=========================== //

const loadStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translateX(-50%) translateY(-50%)'
};
const loadTextStyle = {
    textAlign: 'center',
    fontSize: 'small'
};

ReactDOM.render(
    <Provider store={store}>
        <BrowserRouter>
            <ReactKeycloakProvider
                authClient={keycloakconfig}
                onEvent={eventLogger}
                onTokens={tokenLogger}
                initOptions={initOptions}
                LoadingComponent={
                    <div style={loadStyle}>
                        <Loader
                            type="ThreeDots"
                            color="#00BFFF"
                            height={'200'}
                            width={'200'}
                            timeout={2000000} //3 secs
                        />
                        <p style={loadTextStyle}>잠시만 기다려주세요.</p>
                    </div>
                }
            >
                <App />
            </ReactKeycloakProvider>
        </BrowserRouter>
    </Provider>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
