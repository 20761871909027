// action - state management
import { SET_TITLE, SET_CONTENTS, SET_OPEN, SET_MODAL } from './actions';
// ===========================|| DATE REDUCER ||=========================== //

const initialState = {
    open: false
};

const modalReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_MODAL :{
            return{ 
                ...state,
                title: action.title,
                contents: action.contents,
                open: action.open,
                onCloseBtn: action.onCloseBtn
            }
        }
        case SET_TITLE: {
            return {
                ...state,
                title: action.title
            };
        }
        case SET_CONTENTS: {
            return {
                ...state,
                contents: action.contents
            };
        }
        case SET_OPEN: {
            return {
                ...state,
                open: action.open
            };
        }
        default: {
            return { ...state };
        }
    }
};

export default modalReducer;
