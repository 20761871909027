import { SET_CLUSTER, SET_INDEX } from './actions';
const initialState = '';

const customerIndexReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_INDEX: {
            return {
                ...state,
                index: action.index
            };
        }
        case SET_CLUSTER: {
            return {
                ...state,
                cluster: action.cluster
            };
        }
        default:
            return state;
    }
};

export default customerIndexReducer;
