import React, { useRef, useEffect, useState, useCallback } from 'react';
import { useKeycloak } from '@react-keycloak/web';

import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

//일반적인 상태에서 토큰 만료시간을 검사하는 하는 주기
//또한 실제 토큰의 만료시간이 이 값 이하로 남으면 토큰을 리프레쉬한다.
const CHECK_INTERVAL_SEC = 300;

//사용자가 마지막으로 클릭을 한 시간 이후 이 시간동안 아무런 동작을 하지 않으면 로그아웃을 한다.
const TOKEN_EXPIRE_SEC = 7200;

//사용자의 동작이 없을 때 남은 토큰의 만료시간이 시간이 이 시간보다 적어지면 메세지를 출력한다.
const TOKEN_MESSAGE_SEC = 60;

//사용자가 마지막으로 클릭을 한 시간 이후 이 시간동안 아무런 동작을 하지 않으면 메세지를 출력한다.
const TOKEN_EXPIRE_MESSAGE_SEC = TOKEN_EXPIRE_SEC - TOKEN_MESSAGE_SEC;

const TOKEN_EXPIRE_MESSAGE = '잠시 후 자동으로 로그아웃 될 예정입니다. 서비스 이용이 없으면 자동으로 로그아웃됩니다.';

function SessionCheckSection() {
    const { keycloak } = useKeycloak();
    const [open, setOpen] = useState(false);
    const click_ref = useRef(false);
    const lastClick_ref = useRef(Math.floor(new Date().getTime() / 1000));

    const messageTimeoutId = useRef('');
    const tokenExpireId = useRef('');

    const Alert = React.forwardRef(function Alert(props, ref) {
        return <MuiAlert elevation={10} ref={ref} variant="filled" {...props} />;
    });

    //현재 설정되어있는 setTimeout을 해지하고 다시 설정해준다.
    const tokenExpireSetTimeout = useCallback(() => {
        const curTime = Math.floor(new Date().getTime() / 1000);
        clearTimeout(messageTimeoutId.current);
        messageTimeoutId.current = setTimeout(() => {
            setOpen(true);
        }, ((lastClick_ref.current + TOKEN_EXPIRE_MESSAGE_SEC) - curTime) * 1000);

        clearTimeout(tokenExpireId.current);
        tokenExpireId.current = setTimeout(() => {
            keycloak.logout();
        }, ((lastClick_ref.current + TOKEN_EXPIRE_SEC) - curTime) * 1000);
    }, [keycloak]);

    const onClickCheck = useCallback(() => {
        const oldTime = lastClick_ref.current;
        const curTime = Math.floor(new Date().getTime() / 1000);

        setOpen(false);
        click_ref.current = true;
        lastClick_ref.current = Math.floor(new Date().getTime() / 1000);

        if((curTime - oldTime) > TOKEN_MESSAGE_SEC) {
            tokenExpireSetTimeout();
        }
    }, [tokenExpireSetTimeout]);

    useEffect(() => {
        window.addEventListener("click", onClickCheck, false);
        window.addEventListener("touch", onClickCheck, false);

        const checkEvent = () => {  
            keycloak.updateToken(CHECK_INTERVAL_SEC + 1).catch(() => {
                console.log('updateToken fail');
            });
    
            if(click_ref.current) {
                click_ref.current = false;
                tokenExpireSetTimeout();
            }
        };

        setInterval(checkEvent, CHECK_INTERVAL_SEC * 1000);
        checkEvent();
        tokenExpireSetTimeout();
    }, [keycloak, onClickCheck, tokenExpireSetTimeout]);
    return (
        <>
            <Snackbar open={open}>
                <Alert severity="warning" color="error" sx={{ width: '100%' }}>
                    {TOKEN_EXPIRE_MESSAGE}
                </Alert>
            </Snackbar>
        </>
    );
}
export default React.memo(SessionCheckSection);
