import React, { useCallback, useRef, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
// material-ui
import { makeStyles } from '@material-ui/styles';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import dayjs from 'dayjs';
import { REFRESH, searchDate, setModal } from '../../../../store/actions';
import { Box } from '@material-ui/system';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { DateRange } from 'react-date-range';
import PopupState, { bindPopper, bindToggle } from 'material-ui-popup-state';
import { ButtonBase, Card, CardContent, Fab, Grid, Popper } from '@material-ui/core';
import Avatar from 'ui-component/extended/Avatar';
import { IconCalendar, IconSearch, IconX } from '@tabler/icons';
import Transitions from 'ui-component/extended/Transitions';
import * as rdrLocales from 'react-date-range/dist/locale';

// DatePicker 스타일
const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
        marginLeft: 15
    },
    textField: {
        width: '14vw',
        maxWidth: '200px',
        '& ::-webkit-calendar-picker-indicator': {
            filter: theme.palette.mode === 'dark' ? 'invert(1)' : 'invert(0)'
        }
    },
    searchControl: {
        width: '434px',
        marginLeft: '16px',
        paddingRight: '16px',
        paddingLeft: '16px',
        '& input': {
            background: 'transparent !important',
            paddingLeft: '5px !important'
        },
        [theme.breakpoints.down('lg')]: {
            width: '250px'
        },
        [theme.breakpoints.down('md')]: {
            width: '90%',
            marginLeft: '4px',
            background: theme.palette.mode === 'dark' ? theme.palette.dark[800] : '#fff'
        }
    },
    headerAvatar: {
        ...theme.typography.commonAvatar,
        ...theme.typography.mediumAvatar,
        background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.secondary.light,
        color: theme.palette.mode === 'dark' ? theme.palette.secondary.main : theme.palette.secondary.dark,
        '&:hover': {
            background: theme.palette.mode === 'dark' ? theme.palette.secondary.main : theme.palette.secondary.dark,
            color: theme.palette.mode === 'dark' ? theme.palette.secondary.light : theme.palette.secondary.light
        }
    },
    closeAvatar: {
        float: 'right',
        marginBottom: 5,
        ...theme.typography.commonAvatar,
        ...theme.typography.mediumAvatar,
        background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.orange.light,
        color: theme.palette.orange.dark,
        '&:hover': {
            background: theme.palette.orange.dark,
            color: theme.palette.orange.light
        }
    },
    popperContainer: {
        padding: '10px 0 5px 0',
        zIndex: 1100,
        width: '100%',
        top: '-55px !important',
        // padding: '0 12px',
        [theme.breakpoints.down('sm')]: {
            padding: '0'
        },
        [theme.breakpoints.down('lg')]: {
            maxWidth: '360px'
        }
    },
    cardContent: {
        padding: '12px !important'
    },
    card: {
        background: theme.palette.mode === 'dark' ? theme.palette.dark[900] : '#fff',
        [theme.breakpoints.down('sm')]: {
            border: 0,
            boxShadow: 'none'
        }
    }
}));

const DateSection = () => {
    const classes = useStyles();
    const [currentDate, setCurrentDate] = useState(dayjs().utc());
    const startDateRef = useRef(null);
    const endDateRef = useRef(null);
    const dispatch = useDispatch();
    const onSearchDate = useCallback((startDate, endDate) => dispatch(searchDate(startDate, endDate)), [dispatch]);
    const timezone = useSelector((state) => state.option.timezone);
    const { startDate, endDate } = useSelector(({ date }) => ({
        startDate:
            timezone === 'KST'
                ? dayjs(date.startDate).tz('Asia/Seoul').format('YYYY-MM-DD')
                : dayjs(date.startDate).utc().format('YYYY-MM-DD'),
        endDate: date.endDate
    }));

    const [state, setState] = useState([
        {
            startDate: dayjs(startDate).toDate(),
            endDate: dayjs(endDate).toDate(),
            key: 'selection'
        }
    ]);

    useEffect(() => {
        const timezoneDate = timezone === 'KST' ? dayjs().tz('Asia/Seoul') : dayjs().utc();
        setCurrentDate(timezoneDate);
    }, [timezone]);

    return (
        <>
            {/* 모바일 환경 */}
            <Box sx={{ display: { xs: 'block', md: 'none' } }}>
                <PopupState variant="popper" popupId="demo-popup-popper">
                    {(popupState) => (
                        <>
                            <Box
                                sx={{
                                    ml: 2
                                }}
                            >
                                <ButtonBase sx={{ borderRadius: '12px' }}>
                                    <Avatar variant="rounded" className={classes.headerAvatar} {...bindToggle(popupState)}>
                                        <IconCalendar stroke={1.5} size="1.2rem" />
                                    </Avatar>
                                </ButtonBase>
                            </Box>
                            <Popper {...bindPopper(popupState)} transition className={classes.popperContainer}>
                                {({ TransitionProps }) => (
                                    <Transitions type="zoom" {...TransitionProps} sx={{ transformOrigin: 'top left' }}>
                                        <Card className={classes.card}>
                                            <CardContent className={classes.cardContent}>
                                                <Grid container justifyContent="space-between">
                                                    <Grid
                                                        item
                                                        xs={12}
                                                        style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}
                                                    >
                                                        <DateRange
                                                            locale={rdrLocales.ko}
                                                            format="YYYY-MM-DD"
                                                            onChange={(item) => {
                                                                setState([item.selection]);
                                                            }}
                                                            months={2}
                                                            ranges={state}
                                                            direction="vertical"
                                                            editableDateInputs={true}
                                                            minDate={currentDate.add(-1, 'y').toDate()}
                                                            maxDate={currentDate.toDate()}
                                                        />
                                                        <Grid item xs={12} style={{ padding: 10 }}>
                                                            <Button
                                                                style={{ marginRight: 10 }}
                                                                variant="outlined"
                                                                color="primary"
                                                                onClick={() => {
                                                                    startDateRef.current.value = dayjs(state[0].startDate).format(
                                                                        'YYYY-MM-DD'
                                                                    );
                                                                    endDateRef.current.value = dayjs(state[0].endDate).format('YYYY-MM-DD');
                                                                    onSearchDate(
                                                                        dayjs(state[0].startDate).format('YYYY-MM-DD'),
                                                                        dayjs(state[0].endDate).format('YYYY-MM-DD')
                                                                    );
                                                                    bindToggle({ ...popupState }, popupState.close());
                                                                }}
                                                                startIcon={<IconSearch size="18px" />}
                                                            >
                                                                조회
                                                            </Button>
                                                            <Button
                                                                variant="outlined"
                                                                color="error"
                                                                {...bindToggle(popupState)}
                                                                startIcon={<IconX size="18px" />}
                                                            >
                                                                취소
                                                            </Button>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </CardContent>
                                        </Card>
                                    </Transitions>
                                )}
                            </Popper>
                        </>
                    )}
                </PopupState>
            </Box>
            {/* PC 환경 */}
            <Box sx={{ display: { xs: 'none', md: 'block' } }}>
                <form className={classes.container} noValidate style={{ alignItems: 'center' }}>
                    <TextField
                        label="시작 날짜"
                        inputRef={startDateRef}
                        type="date"
                        defaultValue={startDate}
                        InputProps={{
                            inputProps: {
                                min: currentDate.add(-1, 'y').add(1, 'd').format('YYYY-MM-DD'),
                                max: currentDate.format('YYYY-MM-DD')
                            }
                        }}
                        className={classes.textField}
                        InputLabelProps={{
                            shrink: true
                        }}
                        // value={state.startDate}
                    />
                    <TextField
                        style={{ paddingLeft: '5px' }}
                        label="종료 날짜"
                        inputRef={endDateRef}
                        type="date"
                        defaultValue={endDate}
                        InputProps={{
                            inputProps: {
                                min: currentDate.add(-1, 'y').add(1, 'd').format('YYYY-MM-DD'),
                                max: currentDate.format('YYYY-MM-DD')
                            }
                        }}
                        className={classes.textField}
                        InputLabelProps={{
                            shrink: true
                        }}
                    />

                    <Fab
                        size="small"
                        style={{ marginLeft: '10px', padding: '12px' }}
                        variant="outlined"
                        color="primary"
                        onClick={() => {
                            const startDate = dayjs(startDateRef.current.value).tz('Asia/Seoul');
                            const endDate = dayjs(endDateRef.current.value);
                            const currentDate = dayjs().utc();

                            if (startDate > endDate) {
                                dispatch(setModal('조회기간 오류', '조회기간 종료날이 시작 날보다 이전입니다.', true));
                                return false;
                            }
                            if (endDate.diff(startDate, 'day') === 0) {
                                dispatch(setModal('조회기간 오류', '조회기간은 최소 2일부터 가능합니다.', true));
                                return false;
                            }
                            if (currentDate.diff(startDate, 'years') >= 1) {
                                dispatch(setModal('조회기간 오류', '최근 1년 데이터만 조회가 가능합니다.', true));
                                return false;
                            }

                            if (endDate.diff(startDate, 'years') >= 1) {
                                dispatch(setModal('조회기간 오류', '최대 조회기간은 1년입니다.', true));
                                return false;
                            }
                            onSearchDate(startDateRef.current.value, endDateRef.current.value);
                            setState([
                                {
                                    startDate: startDate.toDate(),
                                    endDate: endDate.toDate(),
                                    key: 'selection'
                                }
                            ]);

                            dispatch({ type: REFRESH });
                        }}
                    >
                        <IconSearch />
                    </Fab>
                </form>
            </Box>
        </>
    );
};
export default DateSection;
