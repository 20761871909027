import { combineReducers } from 'redux';

// reducer import
import customizationReducer from './customizationReducer';
import snackbarReducer from './snackbarReducer';
import dateReducer from './dateReducer';
import customerIndexReducer from './customerIndexReducer';
import userReducer from './userReducer';
import optionReducer from './optionReducer';
import modalReducer from './modalReducer';

// ===========================|| COMBINE REDUCER ||=========================== //

const reducer = combineReducers({
    customization: customizationReducer,
    snackbar: snackbarReducer,
    date: dateReducer,
    customerindex: customerIndexReducer,
    user: userReducer,
    option: optionReducer,
    modal: modalReducer
});

export default reducer;
