import React, { useState, useMemo } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
// import { ReactQueryDevtools } from 'react-query/devtools';
import { useDispatch, useSelector } from 'react-redux';

import { ThemeProvider } from '@material-ui/core/styles';
import { CssBaseline, StyledEngineProvider } from '@material-ui/core';

import { useKeycloak } from '@react-keycloak/web';
// routing
import Routes from 'routes';

// defaultTheme
import themes from 'themes';

// project imports
import NavigationScroll from './layout/NavigationScroll';
import Locales from 'ui-component/Locales';
import UserContext from './store/userContext';
import { SET_CLUSTER, SET_DISPLAYROLE, SET_INDEX, SET_ROLE, SET_NAME } from 'store/actions';

// ===========================|| APP ||=========================== //

const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
      },
    },
  })

const App = () => {
    const customization = useSelector((state) => state.customization);
    const dispatch = useDispatch();
    const { keycloak } = useKeycloak();
    const [userInfo, setUserInfo] = useState();
    const [customers, setCustomers] = useState();

    useMemo(() => {
        keycloak
            ?.loadUserInfo()
            .then((e) => {
                try {
                    const customer = e.customers.sort()[0].split(',');
                    dispatch({ type: SET_INDEX, index: customer[1] ? customer[1] : '' });
                    dispatch({ type: SET_CLUSTER, cluster: customer[2] ? customer[2] : '' });
                    setCustomers(e.customers);
                } catch (e) {
                    console.log(e);
                }

                setUserInfo(e);
                dispatch({ type: SET_NAME, name: e.name, id: e.preferred_username });
            })
            .catch((e) => {
                console.log('사용자 정보를 정상적으로 가져올 수 없습니다.');
            });
        const roleList = [];
        // 우선순위대로 if문 나열
        // stat_admin, stat_user, stat_dev
        if (keycloak?.hasRealmRole('stat_user')) {
            dispatch({ type: SET_DISPLAYROLE, displayRole: '사용자' });
            roleList.push('user');
        }
        if (keycloak?.hasRealmRole('stat_admin')) {
            dispatch({ type: SET_DISPLAYROLE, displayRole: '관리자' });
            roleList.push('admin');
        }
        if (keycloak?.hasRealmRole('stat_dev')) {
            dispatch({ type: SET_DISPLAYROLE, displayRole: '개발자' });
            roleList.push('dev');
        }

        dispatch({ type: SET_ROLE, role: roleList });
    }, [keycloak, dispatch]);

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={themes(customization)}>
                <CssBaseline />
                <Locales>
                    <NavigationScroll>
                        <UserContext.Provider value={{ userInfo: userInfo, customers: customers }}>
                            <QueryClientProvider client={queryClient}>
                                <Routes />
                                {/* <ReactQueryDevtools initialIsOpen={false} /> // 리액트 쿼리 DevTools*/} 
                            </QueryClientProvider>
                        </UserContext.Provider>
                    </NavigationScroll>
                </Locales>
            </ThemeProvider>
        </StyledEngineProvider>
    );
};

export default App;
