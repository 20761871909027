import React, { useCallback, useContext, useEffect, useState } from 'react';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import userContext from 'store/userContext';
import { ButtonBase, Card, CardContent, Grid, InputAdornment, MenuItem, Popper, Select } from '@material-ui/core';
import { Box } from '@material-ui/system';
import { useDispatch, useSelector } from 'react-redux';
import { setCluster, setIndex } from '../../../../store/actions';
import { makeStyles } from '@material-ui/styles';
import Avatar from 'ui-component/extended/Avatar';
import { IconSearch, IconX } from '@tabler/icons';
import PopupState, { bindPopper, bindToggle } from 'material-ui-popup-state';
import Transitions from 'ui-component/extended/Transitions';

// style constant
const useStyles = makeStyles((theme) => ({
    indexBox: {
        width: '14vw',
        marginLeft: '28px'
    },
    searchControl: {
        width: '434px',
        marginLeft: '16px',
        paddingRight: '16px',
        paddingLeft: '16px',
        '& input': {
            background: 'transparent !important',
            paddingLeft: '5px !important'
        },
        [theme.breakpoints.down('lg')]: {
            width: '250px'
        },
        [theme.breakpoints.down('md')]: {
            width: '90%',
            marginLeft: '4px',
            background: theme.palette.mode === 'dark' ? theme.palette.dark[800] : '#fff'
        }
    },
    startAdornment: {
        fontSize: '1rem',
        color: theme.palette.grey[500]
    },
    headerAvatar: {
        ...theme.typography.commonAvatar,
        ...theme.typography.mediumAvatar,
        background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.secondary.light,
        color: theme.palette.mode === 'dark' ? theme.palette.secondary.main : theme.palette.secondary.dark,
        '&:hover': {
            background: theme.palette.mode === 'dark' ? theme.palette.secondary.main : theme.palette.secondary.dark,
            color: theme.palette.mode === 'dark' ? theme.palette.secondary.light : theme.palette.secondary.light
        }
    },
    closeAvatar: {
        ...theme.typography.commonAvatar,
        ...theme.typography.mediumAvatar,
        background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.orange.light,
        color: theme.palette.orange.dark,
        '&:hover': {
            background: theme.palette.orange.dark,
            color: theme.palette.orange.light
        }
    },
    popperContainer: {
        zIndex: 1100,
        width: '99%',
        top: '-55px !important',
        padding: '0 12px',
        [theme.breakpoints.down('sm')]: {
            padding: '0 10px'
        }
    },
    cardContent: {
        padding: '12px !important'
    },
    card: {
        background: theme.palette.mode === 'dark' ? theme.palette.dark[900] : '#fff',
        [theme.breakpoints.down('sm')]: {
            border: 0,
            boxShadow: 'none'
        }
    }
}));

// ===========================|| SEARCH INPUT ||=========================== //

const IndexSection=()=> {
    const classes = useStyles();
    const user = useContext(userContext);
    const [selectIndex, setSelectIndex] = useState(0);
    const [userIndex, setUserIndex] = useState('');
    const [isLoading, setLoading] = useState(true);
    const initIndex = useSelector(({ customerindex }) => customerindex.index);
    const dispatch = useDispatch();
    const onSetIndex = useCallback((index) => dispatch(setIndex(index)), [dispatch]);
    const onSetCluster = useCallback((cluster) => dispatch(setCluster(cluster)), [dispatch]);

    useEffect(() => {
        setLoading(true);
        if (user.customers) {
            setUserIndex(user.customers);
        }
        setLoading(false);
    }, [initIndex, user]);

    const indexChange = async (e) => {
        const value = e.target.value;
        const index = userIndex[value].split(',');

        setSelectIndex(value);
        onSetIndex(index[1] ? index[1] : "");
        onSetCluster(index[2] ? index[2] : "");
    };

    return (
        <>
            <Box sx={{ display: { xs: 'block', md: 'none' } }}>
                <PopupState variant="popper" popupId="demo-popup-popper">
                    {(popupState) => (
                        <>
                            <Box
                                sx={{
                                    ml: 2
                                }}
                            >
                                <ButtonBase sx={{ borderRadius: '12px' }}>
                                    <Avatar variant="rounded" className={classes.headerAvatar} {...bindToggle(popupState)}>
                                        <IconSearch stroke={1.5} size="1.2rem" />
                                    </Avatar>
                                </ButtonBase>
                            </Box>
                            <Popper {...bindPopper(popupState)} transition className={classes.popperContainer}>
                                {({ TransitionProps }) => (
                                    <Transitions type="zoom" {...TransitionProps} sx={{ transformOrigin: 'center left' }}>
                                        <Card className={classes.card}>
                                            <CardContent className={classes.cardContent}>
                                                <Grid container alignItems="center" justifyContent="space-between">
                                                    <Grid item xs>
                                                        <FormControl fullWidth>
                                                            <InputLabel id="index-select">고객사</InputLabel>
                                                            <Select
                                                                className={classes.searchControl}
                                                                defaultValue={0}
                                                                labelId="index-select"
                                                                id="index-select"
                                                                value={selectIndex}
                                                                label="고객사"
                                                                onChange={indexChange}
                                                                startAdornment={
                                                                    <InputAdornment position="start">
                                                                        <IconSearch
                                                                            stroke={1.5}
                                                                            size="1rem"
                                                                            className={classes.startAdornment}
                                                                        />
                                                                    </InputAdornment>
                                                                }
                                                            >
                                                            {userIndex ? (userIndex.sort().map((c, i) => (
                                                                <MenuItem key={c.split(',')[0]} value={i}>
                                                                    {c.split(',')[0]}
                                                                </MenuItem>)
                                                            )) : null}
                                                            </Select>
                                                        </FormControl>
                                                    </Grid>
                                                    <InputAdornment position="end">
                                                        <ButtonBase sx={{ borderRadius: '12px' }}>
                                                            <Avatar
                                                                variant="rounded"
                                                                className={classes.closeAvatar}
                                                                {...bindToggle(popupState)}
                                                            >
                                                                <IconX stroke={1.5} size="1.3rem" />
                                                            </Avatar>
                                                        </ButtonBase>
                                                    </InputAdornment>
                                                </Grid>
                                            </CardContent>
                                        </Card>
                                    </Transitions>
                                )}
                            </Popper>
                        </>
                    )}
                </PopupState>
            </Box>
            <Box className={classes.indexBox} sx={{ display: { xs: 'none', md: 'block' } }}>
                <FormControl fullWidth>
                    <InputLabel id="index-select">고객사</InputLabel>
                    {isLoading ? null : (
                        <Select
                            defaultValue={0}
                            labelId="index-select"
                            id="index-select"
                            value={selectIndex}
                            label="고객사"
                            onChange={indexChange}
                            selectdisplayprosps={{ style: { padding: '11px' } }}
                        >
                            {userIndex ? (userIndex.sort().map((c, i) => (
                                <MenuItem key={c.split(',')[0]} value={i}>
                                    {c.split(',')[0]}
                                </MenuItem>)
                            )) : (
                                <MenuItem></MenuItem>
                            )}
                        </Select>
                    )}
                </FormControl>
            </Box>
        </>
    );
}
export default React.memo(IndexSection);
