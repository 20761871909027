// action - state management
import { SEARCH_DATE } from './actions';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone'; // dependent on utc plugin
dayjs.extend(utc);
dayjs.extend(timezone);
// ===========================|| DATE REDUCER ||=========================== //

const currentDate = dayjs().utc();
const initialState = 
{
    startDate: currentDate.add(-3, 'd').format('YYYY-MM-DD'),
    endDate: currentDate.format('YYYY-MM-DD'),
    toggle: false
};

const dateReducer = (state = initialState, action) => {
    switch (action.type) {
        case SEARCH_DATE: {
            return {
                ...state,
                startDate: action.startDate,
                endDate: action.endDate,
                toggle: !state.toggle
            };
        }
        default: {
            return { ...state };
        }
    }
};

export default dateReducer;
